import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';

@Component({
  selector: 'app-payment-status-model',
  templateUrl: './status-model.component.html'
})
export class ServiceStatusModelComponent implements OnInit {
  
  constructor(public db:DatabaseService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _location: Location) { }
  // data:any = this.db.redeemRequestData
  ngOnInit() {
    
    this.formsInit(this.data);
  }
  todayDate:any
  statusArr:any=['Arrange Pickup','Material Arrival','Pre-Coating Stage','Post-Coating Stage','Dispatch To Customer',];
  preCoatingStageArr:any = ['Decoating','Blasting/Polishing And Type','Cleaning','Batching And Final Batching','Coating Type, Area, Thickness'];
  postCoatingStageArr:any = ['Debatching','Post Treatment','Final Inspection Including Visual Calo and Adhesion Test','Packing'];
  form: FormGroup;
  formsInit(data:any) {
    console.log(data);
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    this.form = this.db.formBuilder.group({
      status: [data.status ? data.status : '',[Validators.required]],
      pre_coating_stage: [data.pre_coating_stage ? data.pre_coating_stage : '',[]],//Validators.required
      post_coating_stage: [data.post_coating_stage ? data.post_coating_stage : '',[]],//Validators.required
      remark: [data.status_remark ? data.status_remark : null ,[]],
      coating_id: [data.encrypt_id ? data.encrypt_id : 0 ,[Validators.required]],
      vehicle_no: [data.vehicle_no ? data.vehicle_no : null ,[]],//Validators.required
      driver_no: [data.driver_no ? data.driver_no : null ,[]],//Validators.required
      driver_name: [data.driver_name ? data.driver_name : null ,[]],//Validators.required
      contact_person_no: [data.contact_person_no ? data.contact_person_no : null ,[]],//Validators.required
      contact_person_name: [data.contact_person_name ? data.contact_person_name : null ,[]],//Validators.required
    })
    console.log(this.form.value);
    
  }
  backClicked() {
    this._location.back();
  }
  
  onSubmit(){
    console.log(this.form.value);
    
    if(this.form.invalid){
      this.form.markAllAsTouched()
      return;
    }
    let reqData = this.form.value
    // if(reqData.gift_status == 'Rejected') {this.db.presentAlert('Alert','Please proveide Remark!');return;}

    this.db.presentLoader();
    this.db.postReq(reqData,'coating/update_status').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.db.matdialog.closeAll();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
      this.db.errDismissLoader();
    })
    
  }
  
}
