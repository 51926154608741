import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
// // import { PaymentStatusModelComponent } from '../../payment/payment-status-model/payment-status-model.component';
import { MatDialog } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import * as moment from 'moment';
// import { RemarkModelComponent } from '../../../remark-model/remark-model.component';


@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html'
})
export class OrderListComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  statusData:any=[];
  tabActiveType:any={};
  
  constructor(private _location: Location, public dialog: MatDialog,public db:DatabaseService) 
  {
    this.getOrderStatus()
    this.filter.cust_type='';
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
  }
  
  ngOnInit() {
    
    
  }
  
  backClicked() {
    this._location.back();
  }
  
  
  tabActive(order_status:any)
  {
    // this.tabActiveType = {};
    // this.tabActiveType[tab] = true; 
    this.filter.order_status = order_status;
    this.start=0;
    this.limit=50;
    this.getData()
  }
  getOrderStatus(){
    
    this.db.presentLoader();
    this.db.postReq({},'order/get_order_status').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.statusData = resp['result'].data;
        this.tabActive( resp['result'].data[0].name);
        // this.getData();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  todayDate :any
  pageNo:any=1;
  tempPageNo =1;
  totalPages:any
  start:any=0;
  limit:any=50;
  totalLength:any;
  filter:any={};
  getData(){
    this.dataNotFound = false;
    this.reqSent = false;
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'order/list').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.data = resp['result'].data;
        if(!this.data.length) this.dataNotFound = true;
        this.totalLength = resp['result'].totalCount;
        this.totalPages = Math.ceil(this.totalLength / this.limit);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  pagination(action){
    
    if(action == 'pageNo'){
      if(this.pageNo > 0 && this.pageNo <=this.totalPages){
        this.start = this.limit*(this.pageNo-1)
      }else{
        this.pageNo = Math.ceil(this.totalLength / this.data.length);
      }
    }
    else if(action=='next'){
      
      if(this.totalLength == this.data.length) return;
      this.start = this.start+this.limit
      this.pageNo ++ ;
    }else{
      if(this.pageNo == 1) return
      this.start = this.start+this.limit
      this.pageNo -- ;
    }
    this.getData();
    this.tempPageNo = this.pageNo
  }
  refresh(){
    this.start = 0;
    this.limit = 50;
    let order_status = this.filter.order_status; 
    this.filter={};
    this.filter.order_status = order_status;    
    this.getData();
  }
  delete(data){
    swal({
      title: "Are you sure?",
      text: "You want delete this order!" ,
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.db.presentLoader();
        data.order_id = data.id;
        this.db.postReq({order_id:data.id},'order/delete').subscribe(resp=>{
          if(resp['status'] == 'success'){
            swal(data.status == 0 ? "Deleted!" : "Restored", {
              icon: "success",
            });
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Your data is safe!");
      }
    });
  }
  
  openStatusDialog(): void {
    // const dialogRef = this.dialog.open(PaymentStatusModelComponent, {
    //   width: '400px',
    //   autoFocus: false,
    //   data: {}
    // });
    
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }
  
  openRemarkDialog(): void {
    // const dialogRef = this.dialog.open(RemarkModelComponent, {
    //   width: '450px',
    //   autoFocus: false,
    //   data: {}
    // });
    
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
  }

  blankTrData :any
  invoiceHeading:any
  detailData:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'performa_invoice_id':id},'performa_invoice/detail').subscribe(async resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.detailData = resp['result'];   
        this.detailData.data.tax_amt = Math.ceil(this.detailData.data.tax_amt)
        console.log(this.detailData);
        this.detailData.hsnWiseData = []
        this.detailData.totalHsnTax= 0
        await this.detailData.orderItem.forEach(element => {
          let i = this.detailData.hsnWiseData.findIndex(r=>r.hsn_code == element.hsn_code);
          if(i==-1){
            this.detailData.hsnWiseData.push({hsn_code:element.hsn_code,taxable_value:element.dispatch_qty_amount,tax_percentage:'18%',tax_amount:element.tax_amt,total_amt:element.total_amt})
          }else{
            this.detailData.hsnWiseData[i].taxable_value += element.dispatch_qty_amount;
            this.detailData.hsnWiseData[i].tax_amount +=element.tax_amt
            this.detailData.hsnWiseData[i].total_amt +=element.total_amt
          }
          console.log(this.detailData.hsnWiseData);
          this.detailData.totalHsnTax+=element.tax_amt
        });

        if(this.detailData.orderItem.length < 9) this.blankTrData = new Array(9-this.detailData.orderItem.length);
        else this.blankTrData = [];
        // this.detailData.hsnWiseData.map(r=>{this.detailData.totalHsnTax+=r.tax_amt})
        setTimeout(() => {
          this.print();
        }, 1000);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.dismissLoader();
    })
    
  }
  
  print(){
    let prtContent = document.getElementById("invoice-detail");
    let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    // WinPrint.close();
  }
  
}
