import { Component, Inject, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
// import { BrandDetailModelComponent } from '../brand-detail-model/brand-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';
// import { RoleDetailModelComponent } from '../role-detail/role-detail-model.component';


@Component({
  selector: 'app-product-group-report-detail',
  templateUrl: './product-group-report-detail.component.html',
})
export class ProductGroupStockReportDetailComponent implements OnInit {
  
  data:any=[];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    @Inject(MAT_DIALOG_DATA) public productData
    ) { }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=10000;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : {
          product_id:this.productData.product_id
        }
      }
      
      this.db.presentLoader();
      this.db.postReq(reqData,'report/stock_report').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    pagination(action){
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        if(this.totalLength == this.data.length) return;
        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start+this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    delete(data){
      swal({
        title: "Are you sure?",
        text: data.status == 1  ? "You want to make this Inactive!" : "You want to make this Active!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.status = data.status == 0 ? 1 : 0;
          data.role_id = data.id;
          this.db.postReq(data,'master/system_user/update').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal(data.status == 0 ? "Inactive!" : "Active", {
                icon: "success",
              });
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    backClicked() {
      this._location.back();
    }
    
    
  }
  