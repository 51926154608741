import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatabaseService } from 'src/app/_services/database.service';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import  swal from 'sweetalert';

@Component({
  selector: 'app-attendance-detail',
  templateUrl: './attendance-detail.component.html',
  // styleUrls: ['./dr-detail.component.scss']
})
export class AttendanceDetailComponent implements OnInit {
  
  constructor(public dialog : MatDialog, public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    this.getDetail(this.activatedroute.snapshot.params.id)
  }
  
  data:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'attendance_id':id},'attendance/dwr').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.data = resp['result'];   
        console.log(this.data);
        // this.getUsers();
        this.data.mapLatLng.map(r=>{
          r.lat = Number(r.lat);
          r.lng = Number(r.lng);
        })
        console.log(this.data.mapLatLng);
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
    
  }
  backClicked() {
    this._location.back();
  }
  previous:any;
  clickedMarker(infowindow) {
    console.log('clickedMarker');
    
    if (this.previous) {
      this.previous.close();
    }
    this.previous = infowindow;
  }
}
