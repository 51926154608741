import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { DatabaseService } from 'src/app/_services/database.service';
import { DialogService } from 'src/app/_services/dialog.service';

@Component({
  selector: 'app-order-detail-status',
  templateUrl: './detail-status.component.html',
})
export class PIDetailStatusComponent implements OnInit {
  
  constructor(public matdialog:MatDialog, @Inject(MAT_DIALOG_DATA) public data :any ,public db :DatabaseService) { }
  statusData:any=[
    {name:"Hold"},
    {name:"Cancel"},
    {name:"Reject"}
  ];

  ngOnInit() {
    this.formsInit({});
    this.getRejectReason();
    console.log(this.data);
    // this.getOrderStatus();
    
  }
  form: FormGroup;
  
  formsInit(data:any) {
    console.log(data);
    
    this.form = this.db.formBuilder.group({
      // this.data.data.data.order_status ? this.data.data.data.order_status : null
      status :[ null,[Validators.required]],
      order_status_remark :[this.data.data.data.order_status_remark ? this.data.data.data.order_status_remark : null,[Validators.required]],
      order_id :[this.data.data.data.id ? this.data.data.data.id : null,[Validators.required]],
    })
  }
  getOrderStatus(){
    
    this.db.presentLoader();
    this.db.postReq({},'performa_invoice/get_pi_status').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.statusData = resp['result'].data;
        // this.getData();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  rejectReasons:any=[];
  getRejectReason(){
    
    this.db.presentLoader();
    this.db.postReq({},'performa_invoice/reject_reason_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.rejectReasons = resp['result'].data;
        // this.getData();
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  onSubmit(){
    console.log(this.form);
    
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return
    }
    console.log(this.form.value);
    this.db.presentLoader();
    this.db.postReq(this.form.value,'performa_invoice/update_status').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        this.matdialog.closeAll()        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
}
