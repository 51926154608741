import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
// import { BrandDetailModelComponent } from '../brand-detail-model/brand-detail-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';
import swal from 'sweetalert';
import { ActivatedRoute } from '@angular/router';
import { ProductDiscountModelComponent } from '../dr-network/product-discount/product-discount.component';
// import { RoleDetailModelComponent } from '../role-detail/role-detail-model.component';

@Component({
  selector: 'app-product-discount',
  templateUrl: './product-discount.component.html',
})
export class ProductDiscountComponent implements OnInit {
  data:any=[];
  discountFilterData=[
    {text:'Less than 10%',value:'< 10'},
    {text:'Less than 20%',value:'< 20'},
    {text:'Less than 30%',value:'< 30'},
    {text:'Less than 40%',value:'< 40'},
    {text:'Less than 50%',value:'< 50'},
    {text:'Less than 60%',value:'< 60'},
    {text:'Less than 70%',value:'< 70'},
    {text:'Less than 80%',value:'< 80'},
    {text:'Less than 90%',value:'< 90'},
  ];
  minQtyFilterData=[
    {text:'Less than 100',value:'< 100'},
    {text:'Less than 200',value:'< 200'},
    {text:'Less than 300',value:'< 300'},
    {text:'Less than 400',value:'< 400'},
    {text:'Less than 500',value:'< 500'},
  ];
  dataNotFound=false
  reqSent=false
  autoFocus?: boolean = true;
  
  constructor(
    private _location: Location,
    public dialog: MatDialog,
    public db:DatabaseService,
    public activatedRoutes:ActivatedRoute
    ) { }
    ngOnInit() {
      this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
      this.getData();
    }
    todayDate :any
    pageNo:any=1;
    tempPageNo =1;
    totalPages:any
    start:any=0;
    limit:any=50;
    totalLength:any;
    filter:any={};
    getData(){
      this.dataNotFound = false;
      this.reqSent = false;
      let reqData = {
        limit : this.limit,
        start : this.start,
        filter : this.filter,
        // customer_id : this.activatedRoutes.snapshot.params.id
      }
      this.db.presentLoader();
      this.db.postReq(reqData,'dis_network/product_dis_list').subscribe(resp=>{
        this.reqSent = true;
        if(resp['status'] == 'success'){
          this.data = resp['result'].data;
          if(!this.data.length) this.dataNotFound = true;
          this.totalLength = resp['result'].totalCount;
          this.totalPages = Math.ceil(this.totalLength / this.limit);
        }else{
          if(resp['message']=='Invalid token'){
            this.db.sessionExpire();
            return;
          }
          this.db.presentAlert(resp['status'],resp['message'])
        }
      },err=>{
        
      })
    }
    pagination(action){
      if(action == 'pageNo'){
        if(this.pageNo > 0 && this.pageNo <=this.totalPages){
          this.start = this.limit*(this.pageNo-1)
        }else{
          this.pageNo = Math.ceil(this.totalLength / this.data.length);
        }
      }
      else if(action=='next'){
        if(this.totalLength == this.data.length) return;
        this.start = this.start+this.limit
        this.pageNo ++ ;
      }else{
        if(this.pageNo == 1) return
        this.start = this.start+this.limit
        this.pageNo -- ;
      }
      this.getData();
      this.tempPageNo = this.pageNo
    }
    refresh(){
      this.start = 0;
      this.limit = 50;
      this.filter = {};
      this.getData();
    }
    delete(data){
      swal({
        title: "Are you sure?",
        text: data.status == 1  ? "You want to make this Inactive!" : "You want to make this Active!",
        icon: "warning",
        buttons: ["Cancel", "Confirm"],
        dangerMode: true,
      })
      .then((willDelete) => {        
        if (willDelete) {
          this.db.presentLoader();
          data.status = data.status == 0 ? 1 : 0;
          data.role_id = data.id;
          this.db.postReq(data,'master/update_role').subscribe(resp=>{
            if(resp['status'] == 'success'){
              swal(data.status == 0 ? "Inactive!" : "Active", {
                icon: "success",
              });
            }else{
              if(resp['message']=='Invalid token'){
                this.db.sessionExpire();
                return;
              }
              this.db.presentAlert(resp['status'],resp['message'])
            }
          })
          
        } else {
          swal("Your data is safe!");
        }
      });
    }
    backClicked() {
      this._location.back();
    }
    openProductDiscDialog(data){
      data.customer_id = this.activatedRoutes.snapshot.params.id
      console.log(data);
      
      const dialogRef = this.dialog.open(ProductDiscountModelComponent, {
        width: '768px',
        autoFocus: false,
        data: { data:data}
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.getData()
      });
    }
  }
  