import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, Validators } from '@angular/forms';
import { DatabaseService } from 'src/app/_services/database.service';
import swal from 'sweetalert';
import { CustomEventsService } from 'src/app/_services/custom-events.service';
// import { type } from 'os';

@Component({
  selector: 'app-dr-add',
  templateUrl: './dr-add.component.html',
  // styleUrls: ['./dr-add.component.scss']
})
export class DrAddComponent implements OnInit {
  type:any = this.activatedroute.snapshot.params.type
  typeName:any = this.type == 1 ? 'Distributor' : 'Customer'
  
  constructor(public events:CustomEventsService,public activatedroute:ActivatedRoute ,private _location: Location,public db:DatabaseService) { }
  
  ngOnInit() {
    this.getDrType()
    this.db.getStates();
    this.getBrands();
    this.getUsers();
    this.formsInit();
  }
  getDrType(){
    this.db.postReq({},'dis_network/type_list').subscribe(resp=>{
      this.db.drType = resp['result'].data
      this.db.drType.map(r=>r.id = r.id.toString())      
    })
  }
  // states=[];
  // districts=[];
  // postal(){
  //   this.db.postReq({start:0,limit:160,filter: {}},'master/postal_master').subscribe(resp=>{
  //     resp['result'].data.map(r=>{
  //       if(!this.states.includes(r.state_name)){
  //         this.states.push(r.state_name);
  //       }
  //       if(!this.districts.includes(r.district_name)){
  //         this.districts.push(r.state_name);
  //       }
  //     })
  //     // console.log(this.states,this.districts);
  
  //   })
  // }
  
  
  form: FormGroup;
  
  formsInit() {
    this.form = this.db.formBuilder.group({
      company_name: [  null , [Validators.required]],
      cust_code: [  null , [Validators.required]],
      gst_no: [  null , [Validators.required,Validators.pattern(/^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/),Validators.minLength(15)]],
      cust_type_id: [  null , [Validators.required]],
      status: [  '1' , [Validators.required]],
      email: [  null , [Validators.required,Validators.pattern(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)]],
      mobile: [  null , [Validators.required,Validators.pattern(/^((\\+91-?)|0)?[0-9]{10}$/)]],
      landline_no: [  null , [Validators.required]],
      username: [  null , [Validators.required]],
      password: [  null , [Validators.required]],
      credit_day: [  null , [Validators.required]],
      street: [  null , [Validators.required]],
      state_name: [  null , [Validators.required]],
      district_name: [  null , [Validators.required]],
      city: [  null , [Validators.required]],
      zone: [  null , []],
      area: [  null , []],
      pincode: [  null , []],
      cust_id: [  null],
      cp_email: [null],
      cp_designation: [null],
      cp_mobile: [null],
      cp_name: [null],
      contactDetail: [[] , []],
      brandDetail: [[] , [Validators.required]],
      assignedUser: [{} , []],
    })
    
    this.form.controls['cust_type_id'].setValue(this.type)
    
    
  }
  onSubmit(){
    console.log(this.form.value);
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return
    }
    let reqData:any = {};
    reqData.basicDetail = this.form.value
    reqData.basicDetail.cust_type = this.db.drType.filter(r=>r.id = reqData.basicDetail.cust_type_id)[0].name
    reqData.contactDetail = this.form.value.contactDetail;
    reqData.assignedUser = this.form.value.assignedUser;
    // }];
    reqData.brandDetail = this.form.value.brandDetail;
    console.log(reqData);
    // return
    if(this.form.value.contactDetail.length==0){
      this.db.presentAlert('Error','Please Enter atleast one contact detail')
      return;
    }
    reqData.status = this.form.value.status
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/add_network').subscribe(resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.db.successAlert(resp['status'],resp['message']);
        // this.events.publish('tabActive',{})
        this.backClicked();
        this.db.router.navigateByUrl('dr-list/'+this.form.value.cust_type_id,{replaceUrl:true})
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errDismissLoader();
    })
    
  }
  
  backClicked() {
    this._location.back();
  }
  addContactDetails(){
    console.log(this.form.value,this.form.value.cp_name ,this.form.value.cp_designation ,this.form.value.cp_email ,this.form.value.cp_mobile)
    if(!this.form.value.cp_name || !this.form.value.cp_designation || !this.form.value.cp_email || !this.form.value.cp_mobile){
      this.db.presentAlert('Error','Please fill all the required details to continue!');
      return;
    }
    var emailpattern = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
    console.log(emailpattern.test(this.form.value.cp_email));
    
    if(!emailpattern.test(this.form.value.cp_email)){
      this.db.presentAlert('Error','Please enter a valid email!');
      return;
    }
    var mobilepattern = /^((\\+91-?)|0)?[0-9]{10}$/
    if(!mobilepattern.test(this.form.value.cp_mobile)){
      this.db.presentAlert('Error','Please enter a valid mobile!');
      return;
    }
    this.form.controls['contactDetail'].setValue([...this.form.value.contactDetail,{name:this.form.value.cp_name ,email:this.form.value.cp_email,designation:this.form.value.cp_designation,mobile:this.form.value.cp_mobile}])
    this.form.controls['cp_name'].setValue(null);
    this.form.controls['cp_designation'].setValue(null);
    this.form.controls['cp_mobile'].setValue(null);
    this.form.controls['cp_email'].setValue(null);
  }
  deleteContact(index){
    swal({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {  
        this.form.controls['contactDetail'].setValue(this.form.value.contactDetail.filter((r,i)=> i != index ))
      } else {
        swal("Your data is safe!");
      }
    });
    
  }
  brandsData:any=[]
  filteredbrandsData:any=[];
  getBrands(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/brand').subscribe(resp=>{
      if(resp['status'] == 'success'){
        resp['result'].data.map(r=>{
          this.brandsData.push({brand_name:r.name,brand_id:r.id})
          this.filteredbrandsData.push({brand_name:r.name,brand_id:r.id})
        });
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  usersData:any=[]
  filteredUsersData:any=[]
  getUsers(){
    let reqData = {
      limit : 30000,
      start : 0,
      filter : {}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'dis_network/system_user').subscribe(resp=>{
      if(resp['status'] == 'success'){
        resp['result'].data.map(r=>{
          r.user_id = r.id;
        })
        this.usersData = resp['result'].data;
        this.filteredUsersData = this.usersData
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  postalData:any=[];
  pincodeHandler(){
    let reqData = {
      filter:{pincode:this.form.value.pincode}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'master/getDetailByPincode').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.postalData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  selectPostal(data){
    this.form.controls['state_name'].setValue(data.state_name);
    this.form.controls['district_name'].setValue(data.district_name);
    this.form.controls['city'].setValue(data.city);
    this.form.controls['area'].setValue(data.area);
    this.form.controls['zone'].setValue(data.zone);
  }
}
