import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog } from '@angular/material';
import { InvoiceModelComponent } from 'src/app/invoice/invoice-model/invoice-model.component';
import { DatabaseService } from 'src/app/_services/database.service';
import  swal from 'sweetalert';
import * as moment from 'moment';

@Component({
  selector: 'app-invoice-list',
  // styleUrls:['./invoice-list.component.scss'],
  templateUrl: './invoice-list.component.html'
})
export class InvoiceListComponent implements OnInit {
  data:any=[];
  dataNotFound=false
  reqSent=false
  status: boolean = false;
  invoice:any=[];
  constructor(private _location: Location, public dialog: MatDialog,public db:DatabaseService) {}
  blankTrData :any
  ngOnInit() {
    this.todayDate =  moment(new Date ()).format('YYYY-MM-DD')
    this.getData();
  }
  todayDate :any
  pageNo:any=1;
  tempPageNo =1;
  totalPages:any
  start:any=0;
  limit:any=50;
  totalLength:any=0;
  filter:any={};
  getData(){
    let reqData = {
      limit : this.limit,
      start : this.start,
      filter : this.filter
    }
    this.reqSent=false;
    this.db.presentLoader();
    this.db.postReq(reqData,'invoice/list').subscribe(resp=>{
      this.reqSent=true;
      if(resp['status'] == 'success'){
        this.data = resp['result'].data;
        if(!this.data.length) this.dataNotFound = true;
        this.totalLength = resp['result'].totalCount;
        this.totalPages = Math.ceil(this.totalLength / this.limit);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  pagination(action){
    
    if(action == 'pageNo'){
      if(this.pageNo > 0 && this.pageNo <=this.totalPages){
        this.start = this.limit*(this.pageNo-1)
      }else{
        this.pageNo = Math.ceil(this.totalLength / this.data.length);
      }
    }
    else if(action=='next'){
      
      if(this.totalLength == this.data.length) return;
      this.start = this.start+this.limit
      this.pageNo ++ ;
    }else{
      if(this.pageNo == 1) return
      this.start = this.start+this.limit
      this.pageNo -- ;
    }
    this.getData();
    this.tempPageNo = this.pageNo
  }
  refresh(){
    this.start = 0;
    this.limit = 50;
    let order_status = this.filter.order_status; 
    this.filter={};
    this.filter.order_status = order_status;    
    this.getData();
  }
  delete(data){
    swal({
      title: "Are you sure?",
      text: "You want to delete this!",
      icon: "warning",
      buttons: ["Cancel", "Confirm"],
      dangerMode: true,
    })
    .then((willDelete) => {        
      if (willDelete) {
        this.db.presentLoader();
      
        data.invoice_id = data.encrypt_id;
        this.db.postReq(data,'invoice/delete').subscribe(resp=>{
          if(resp['status'] == 'success'){
            swal("Deleted", {
              icon: "success",
            });
            this.getData();
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        })
        
      } else {
        swal("Your data is safe!");
      }
    });
  }
  backClicked() {
    this._location.back();
  }
  
  clickEvent()
  {
    this.status = !this.status;       
  }
  
  openInvoiceDetail(): void {
    const dialogRef = this.dialog.open(InvoiceModelComponent, {
      width: '768px',
      autoFocus: false,
      data: {}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  invoiceHeading:any
  detailData:any={};
  getDetail(id){
    this.db.presentLoader();
    
    this.db.postReq({'invoice_id':id},'invoice/detail').subscribe(async resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.detailData = resp['result'];   
        this.detailData.data.tax_amt = Math.ceil(this.detailData.data.tax_amt)
        console.log(this.detailData.data.tax_amt);
        this.detailData.hsnWiseData = []
        this.detailData.totalHsnTax= 0
        await this.detailData.orderItem.forEach(element => {
          let i = this.detailData.hsnWiseData.findIndex(r=>r.hsn_code == element.hsn_code);
          if(i==-1){
            this.detailData.hsnWiseData.push({hsn_code:element.hsn_code,taxable_value:element.dispatch_qty_amount,tax_percentage:'18%',tax_amount:element.tax_amt,total_amt:element.total_amt})
          }else{
            this.detailData.hsnWiseData[i].taxable_value += element.dispatch_qty_amount;
            this.detailData.hsnWiseData[i].tax_amount +=element.tax_amt
            this.detailData.hsnWiseData[i].total_amt +=element.total_amt
          }
          console.log(this.detailData.hsnWiseData);
          this.detailData.totalHsnTax+=element.tax_amt
        });

        if(this.detailData.orderItem.length < 9) this.blankTrData = new Array(9-this.detailData.orderItem.length);
        else this.blankTrData = [];
        // this.detailData.hsnWiseData.map(r=>{this.detailData.totalHsnTax+=r.tax_amt})
        setTimeout(() => {
          this.print();
        }, 1000);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.dismissLoader();
    })
    
  }
  
  print(){
    let prtContent = document.getElementById("invoice-detail");
    let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    // WinPrint.close();
  }
  stickerData:any={}
  printSticker(data){
    
    this.db.presentLoader();
    
    this.db.postReq({'invoice_id':data.encrypt_id},'invoice/detail').subscribe(async resp=>{
      this.db.dismissLoader();
      if(resp['status'] == 'success'){
        this.detailData = resp['result'];   
        this.detailData.data.tax_amt = Math.ceil(this.detailData.data.tax_amt)
        console.log(this.detailData.data.tax_amt);
        this.detailData.hsnWiseData = []
        this.detailData.totalHsnTax= 0
        await this.detailData.orderItem.forEach(element => {
          let i = this.detailData.hsnWiseData.findIndex(r=>r.hsn_code == element.hsn_code);
          if(i==-1){
            this.detailData.hsnWiseData.push({hsn_code:element.hsn_code,taxable_value:element.net_total,tax_percentage:'18%',tax_amount:element.tax_amt,total_amt:element.total_amt})
          }else{
            this.detailData.hsnWiseData[i].taxable_value += element.net_total;
            this.detailData.hsnWiseData[i].tax_amount +=element.tax_amt
            this.detailData.hsnWiseData[i].total_amt +=element.total_amt
          }
          console.log(this.detailData.hsnWiseData);
          this.detailData.totalHsnTax+=element.tax_amt
        });
        // this.detailData.hsnWiseData.map(r=>{this.detailData.totalHsnTax+=r.tax_amt})
        setTimeout(() => {
          let prtContent = document.getElementById("sticker");
          let WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
          WinPrint.document.write(prtContent.innerHTML);
          WinPrint.document.close();
          WinPrint.focus();
          WinPrint.print();
          
        }, 1000);
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.dismissLoader();
    })
    
    
    
  }

  downloadExcel(){
    let reqData = {
      filter : this.filter
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'invoice/list/excel').subscribe(resp=>{
      this.reqSent = true;
      if(resp['status'] == 'success'){
        this.db.exportAsExcelFile(resp['result'].data,resp['result'].file_name ? resp['result'].file_name : 'exported_data');
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      this.db.errHandler(err);
    })
  }
  
}
