import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { DatabaseService } from 'src/app/_services/database.service';
import * as moment from 'moment';

@Component({
  selector: 'app-invoice-add',
  templateUrl: './invoice-add.component.html'
})
export class InvoiceAddComponent implements OnInit {
  form:any={};
  constructor(private _location: Location,public db:DatabaseService) { }
  ngOnInit() {
    this.form.freight_amt = 0;
    this.getCust();
    
    this.form.invoice_date = moment(new Date()).format("YYYY-MM-DD");
    this.form.delivery_date = moment(new Date()).format("YYYY-MM-DD");
    this.form.payment_date = moment(new Date()).format("YYYY-MM-DD");
  }
  
  calcTax(){
    if(!this.form.freight_amt && !this.form.amount) return;
    
    this.form.total_amt = parseFloat(this.form.freight_amt) + parseFloat(this.form.amount)
    if(this.drGst=='06'){
      this.form.cgst_amt = (this.form.total_amt /100) * 9
      this.form.cgst_amt = parseFloat(this.form.cgst_amt) .toFixed(2)
      this.form.sgst_amt = (this.form.total_amt /100) * 9
      this.form.sgst_amt = parseFloat(this.form.sgst_amt) .toFixed(2)
      
      this.form.igst_amt = 0
    }else{
      this.form.cgst_amt = 0
      this.form.sgst_amt = 0
      
      this.form.igst_amt = (this.form.total_amt /100) * 18
      this.form.igst_amt = parseFloat(this.form.igst_amt) .toFixed(2)
    }
    this.form.invoice_total = parseFloat(this.form.igst_amt) + parseFloat(this.form.cgst_amt) + parseFloat(this.form.sgst_amt) +parseFloat(this.form.total_amt)
    this.form.invoice_total = this.form.invoice_total.toFixed(2)
  }
  drGst:any
  // filter:any={}
  custData:any=[]
  filteredCustData:any=[]
  getCust(){
    let reqData = {
      limit : 100,
      start : 0,
      filter : {company_name:this.form.company_name}
    }
    this.db.presentLoader();
    this.db.postReq(reqData,'invoice/dr_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.custData = resp['result'].data;
        this.filteredCustData = resp['result'].data;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  orderData:any=[]
  getOrders(){    
    let reqData = {customer_id:this.form.customer_id}
    
    this.db.presentLoader();
    this.db.postReq(reqData,'invoice/get_order_list').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.orderData = resp['result'].data;
        this.orderData.map(r=>r.checked = false)
        this.getInvoiceNo();
        this.orderItems = [];
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  orderItems:any=[];
  getOrderItems(){
    let order_ids = [];
    this.orderData.map(r =>{ if (r.checked) if(!order_ids.includes(r.id))order_ids.push(r.id); } )
    
    if(!order_ids.length) return;
    
    let reqData = {order_id:order_ids}
    this.orderItems = [];
    this.db.presentLoader();
    this.db.postReq(reqData,'invoice/get_order_detail').subscribe(async resp=>{
      if(resp['status'] == 'success'){
        await resp['result'].data.map(async r=>{
          await r.order_detail.map(item=>{
            item.dispatched_qty = item.dispatch_qty;
            item.dispatch_qty = item.pending_qty;
            item.order_no = r.order_no;
          })
          this.orderItems = [...this.orderItems,...r.order_detail];
        })
        console.log(this.orderItems);
        await this.orderItems.map((r,i)=>{
          this.calculateDispatchAmount(i);
        })

        this.calculateAmount()
        
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  getInvoiceNo(){
    let reqData = {customer_id:this.form.customer_id}
    this.db.presentLoader();
    this.db.postReq(reqData,'invoice/get_invoice_no').subscribe(resp=>{
      if(resp['status'] == 'success'){
        this.form.invoice_no = resp['result'].data.invoice_no;
      }else{
        if(resp['message']=='Invalid token'){
          this.db.sessionExpire();
          return;
        }
        this.db.presentAlert(resp['status'],resp['message'])
      }
    },err=>{
      
    })
  }
  
  backClicked() {
    this._location.back();
  }
  changeListener($event): void {
    console.log($event);
    
    var file = $event.target.files[0];
    var reader = new FileReader();
    var image
    reader.onloadend = function () {
      image = reader.result;
    }
    setTimeout(() => {
      console.log(image);
    }, 100);
    
    reader.readAsDataURL(file);
    
  }
  async onSubmit(){
    let order_ids = [];
    await this.orderData.map(r =>{ if (r.checked) order_ids.push(r.id); } )
    this.form.orderIds = order_ids;
    this.form.invoiceItem = this.orderItems;
    this.form.total_item = this.orderItems.length;
    let total_qty = 0 ;
    this.orderItems.map(r=>
      total_qty+=  r.dispatch_qty
      )
      this.form.total_qty = total_qty ;
      this.form.total_qty = total_qty ;
      this.form.tax_amt = parseFloat(this.form.sgst_amt) + parseFloat(this.form.cgst_amt) + parseFloat(this.form.igst_amt) ;
      this.form.order_no = '';
      this.orderItems.map(r=>
        this.form.order_no =  this.form.order_no + ','+r.order_no
        )
        this.form.order_no = this.form.order_no.replace(',','');
        
        this.db.presentLoader();
        this.db.postReq(this.form,'invoice/add').subscribe(resp=>{
          this.db.dismissLoader();
          if(resp['status'] == 'success'){
            this.db.successAlert(resp['status'],resp['message']);
            this.backClicked();
          }else{
            if(resp['message']=='Invalid token'){
              this.db.sessionExpire();
              return;
            }
            this.db.presentAlert(resp['status'],resp['message'])
          }
        },err=>{
          this.db.errDismissLoader();
        })
        
      }
      paymentDateHandler(){    
        let index = this.custData.findIndex(r=>(r.id === this.form.customer_id))
        if(index!=-1){
          this.form.payment_date = moment(new Date()).add(this.custData[index].credit_day, 'days').format("YYYY-MM-DD");
        }
      }
      taxHandler(){
        console.log('taxHandler');
        
        let i = this.custData.findIndex(r=>r.id === this.form.customer_id)
        console.log(i,this.custData[i],this.form.customer_id);
        
        this.drGst = this.custData[i].gst_no.substring(0,2);
        console.log(this.drGst);
        
      }
      async calculateAmount(){
        let amount = 0
        await this.orderItems.forEach(element => {
          amount +=  (element.sub_total / element.order_qty) * element.dispatch_qty
        });
        this.form.amount = amount.toFixed(2)
        this.calcTax();
      }
      calculateDispatchAmount(index){
        console.log('calculateDispatchAmount');
        if(this.orderItems[index].dispatch_qty > this.orderItems[index].stock_qty){
          this.orderItems[index].dispatch_qty = 0
          this.db.presentAlert('Alert','Not Enough Stock!');return;
        }
        if(this.orderItems[index].dispatch_qty > this.orderItems[index].pending_qty){
          return;
        }
        // this.orderItems[index].dispatch_qty_amount =  (this.orderItems[index].total_amt / this.orderItems[index].order_qty) * this.orderItems[index].dispatch_qty;
        this.orderItems[index].dispatch_qty_amount =  (this.orderItems[index].sub_total / this.orderItems[index].order_qty) * this.orderItems[index].dispatch_qty;
        this.orderItems[index].dispatch_qty_amount = this.orderItems[index].dispatch_qty_amount.toFixed(2);
      }
    }
    